import React from "react";
import ClimaBox from "./ClimaBox";

const Home = () => {
  return (
    <>
      <ClimaBox />
    </>
  );
};

export default Home;
