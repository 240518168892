export const weatherIcons = {
  "01d": "clear-sky-day.png",
  "01n": "clear-sky-night.png",
  "02d": "few-clouds-day.png",
  "02n": "few-clouds-night.png",
  "03d": "scattered-clouds.png",
  "03n": "scattered-clouds.png",
  "04d": "broken-clouds.png",
  "04n": "broken-clouds.png",
  "09d": "shower-rain.png",
  "09n": "shower-rain.png",
  "10d": "rain-day.png",
  "10n": "rain-night.png",
  "11d": "thunderstorm-day.png",
  "11n": "thunderstorm-night.png",
  "13d": "snow-day.png",
  "13n": "snow-night.png",
  "50d": "mist-day.png",
  "50n": "mist-night.png",
};
