import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      x="0"
      y="0"
      enableBackground="new 0 0 420.7 420.701"
      version="1.1"
      viewBox="0 0 420.7 420.701"
      xmlSpace="preserve"
    >
      <path
        d="M76.5 19.15C34.4 19.15 0 53.55 0 95.65s34.4 76.5 76.5 76.5 76.5-34.4 76.5-76.5-34.4-76.5-76.5-76.5zm0 114.7c-21 0-38.2-17.2-38.2-38.2s17.2-38.2 38.2-38.2 38.2 17.2 38.2 38.2-17.1 38.2-38.2 38.2zM401.6 286.85c-11.5 0-19.1 7.6-19.1 19.1 0 32.5-24.9 57.4-57.4 57.4h-38.2c-32.5 0-57.4-24.9-57.4-57.4v-191.2c0-32.5 24.9-57.4 57.4-57.4h38.2c32.5 0 57.4 24.9 57.4 57.4 0 11.5 7.6 19.1 19.1 19.1s19.1-7.6 19.1-19.1c0-53.5-42.1-95.6-95.6-95.6h-38.2c-53.5 0-95.601 42.1-95.601 95.6v191.2c0 53.5 42.101 95.601 95.601 95.601h38.2c53.5 0 95.6-42.101 95.6-95.601.101-11.5-7.6-19.1-19.1-19.1z"
        fill="#db9426" // Puedes cambiar "#FF0000" por el color que desees
      ></path>
    </svg>
  );
}

export default Icon;
