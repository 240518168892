import React from "react";

const Hero = () => {
  return (
    <>
      <section className="total-hero">
        <div className="hero-wrapper">
          <img
            src="https://i.ibb.co/wYSMcYD/nube-blanca-cielo-azul.jpg"
            alt=""
          />
          <h4>Clima</h4>
        </div>
      </section>
    </>
  );
};

export default Hero;
